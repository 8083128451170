import { useState, useEffect } from 'react';
import axios from 'axios';
import { Post } from 'common/interfaces/api';
import Icon from 'components/common/Icon';
import styles from './PostReActions.module.scss';
import useGlobalStore from 'store/global';
import { numberToString, useMediaQuery } from 'common/utils';
import cn from 'classnames';
import { useRef } from 'react';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import { ScreenName } from 'common/utils/pp_tracking';
export type Props = {
  post: Post;
  isPostDetail?: boolean;
  handleClickComment?: () => void;
  showViewCount?: boolean;
  smallSize?: boolean;
  showNumber?: boolean;
  screen: ScreenName;
};

export default function PostReActions({
  post,
  isPostDetail = false,
  handleClickComment,
  showViewCount = false,
  smallSize = false,
  showNumber = false,
  screen,
}: Props): JSX.Element {
  const showLogin = useGlobalStore((state) => state.showLogin);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  // use local state to make sure user's action is immediate
  const [isLiked, setIsLiked] = useState(post.is_liked);
  const [isShared, setIsShared] = useState(post.is_shared);
  const [likeCount, setLikeCount] = useState(post.like_count);
  const setPostToShareByPopup = useGlobalStore(
    (state) => state.setPostToShareByPopup
  );
  const isMobileScreen = useMediaQuery('(max-width: 767px)');

  const viewCountWidthPer = showViewCount ? 16 : 0;
  const shareMenuRef = useRef();

  useEffect(() => {
    setIsLiked(post.is_liked);
    setIsShared(post.is_shared);
    setLikeCount(post.like_count);
  }, [
    post.like_count,
    post.is_shared,
    post.is_liked,
    post.is_saved,
    post.save_count,
  ]);

  const postDetailsUrl = `/${post.user_info.username}/posts/${post.id}`;

  const trackAmpEvent = (
    eventName: 'Like Post' | 'Share Post' | 'Copy Post Url'
  ) => {
    track(eventName, {
      post_type: post.type,
      media_type: post.media?.[0]?.type,
      comment_count: post.comment_count,
      like_count: post.like_count,
      post_user_id: post.user_info?.id?.toString(),
      is_followed: post.user_info?.is_followed,
      page: screen,
    });
  };
  const handleLikeClick = async (postId: number, isLiked: boolean) => {
    trackAmpEvent('Like Post');
    if (currentAuth) {
      const action = isLiked ? 'unlike' : 'like';
      if (isLiked) {
        setIsLiked(false);
        setLikeCount((count) => count - 1);
      } else {
        setIsLiked(true);
        setLikeCount((count) => count + 1);
      }
      await axios.post(`/posts/${postId}/reaction?action=${action}`, {});
    } else {
      showLogin({ redirectUrl: postDetailsUrl });
    }
  };

  const handleShareClick = async (postId: number) => {
    if (!currentAuth) {
      showLogin({ redirectUrl: postDetailsUrl });
      return;
    }
    if (isShared) return;

    try {
      await axios.post(`/posts/${postId}/shares`, {});
      if (!isShared) {
        setIsShared(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const iconSize = useMemo(() => {
    if (smallSize) {
      return isMobileScreen ? 16 : 20;
    } else {
      return isMobileScreen ? 16 : 20;
    }
  }, [smallSize, isMobileScreen]);
  return (
    <div
      className={cn(styles.newPostReactionWrapper, {
        [styles.isPostDetail]: isPostDetail,
        [styles.smallSize]: smallSize,
      })}
    >
      <div className={styles.line}></div>
      <div className={styles.postReActionsWrapper}>
        <div
          className={styles.icon}
          style={{ width: `${(100 - viewCountWidthPer) / 3}%` }}
          onClick={() => handleLikeClick(post.id, isLiked)}
        >
          <Icon
            name="heart"
            width={iconSize}
            height={iconSize}
            isOn={isLiked}
            hasOn
          />
          <div className={styles.numberWrapper}>
            {!smallSize && <span className={styles.text}>いいね</span>}
            {(showNumber || isLiked) && (
              <div className={styles.number}>
                {numberToString(likeCount, 1)}
              </div>
            )}
          </div>
        </div>
        <div className={styles.separate} />
        <a
          onClick={() => handleClickComment && handleClickComment()}
          style={{ width: `${(100 - viewCountWidthPer) / 3}%` }}
        >
          <div className={styles.icon}>
            <Icon name="comment" width={iconSize} height={iconSize} />
            <div className={styles.numberWrapper}>
              {!smallSize && <span className={styles.text}>コメント</span>}
              {showNumber && (
                <div className={styles.number}>
                  {numberToString(post.comment_count, 1)}
                </div>
              )}
            </div>
          </div>
        </a>
        <div className={styles.separate} />
        <div
          className={cn(styles.icon, styles.shareMenuTrigger)}
          style={{ width: `${(100 - viewCountWidthPer) / 3}%` }}
          ref={shareMenuRef}
          onClick={() => {
            if (!currentAuth) {
              showLogin({ redirectUrl: postDetailsUrl });
              return;
            }
            void handleShareClick(post.id);
            setPostToShareByPopup(post);
          }}
        >
          <Icon
            name="share"
            width={iconSize}
            hasOn
            isOn={isShared}
            height={iconSize}
          />
          <div className={styles.numberWrapper}>
            {!smallSize && <span className={styles.text}>シェア</span>}
            {(showNumber || isShared) && (
              <div className={styles.number}>
                {numberToString(post.share_count, 1)}
              </div>
            )}
          </div>
        </div>
        {showViewCount && (
          <>
            <div className={styles.separate} />
            <div
              className={cn(styles.icon, styles.viewCount)}
              style={{ width: `${viewCountWidthPer}%` }}
            >
              <Icon name="view-black" width={iconSize} height={iconSize} />
              <div className={styles.numberWrapper}>
                <div className={styles.number}>
                  {numberToString(post.view_count, 1)}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
